import React from 'react';
import './footer.scss';
import Svg from "../svg/Svg";
import common_data from '../../data/data.json';

const Footer = ({ data }) => {
	const payment_system = {
		visa: './static/images/icons/visa.svg',
		paykeeper: './static/images/icons/pay_keeper.svg',
		mastercard: './static/images/icons/master_card.svg',
	};

	return (
		<footer className='footer'>
			<div className="footer__copyright">© {new Date().getFullYear()} { data.copyright }</div>
			<a href={common_data.privacy_policy || 'https://kf.expert/privacy-policy'} className='footer__privacy-policy'>{ data.privacy_policy.text }</a>
			<div className='footer__payment-system'>
				{ data.payment_system && data.payment_system.map((link) =>
					<img src={ payment_system[link] } alt="payment system" key={payment_system[link]}/>
				) }
			</div>
			<div className="footer__social-box">
				{ data.social.length ? data.social.map((link, index) =>
					<a href={ link.href } className='footer__social' key={index + link.icon}>
						<span></span>
						<Svg name={ link.icon }/>
					</a>
				) : null }
			</div>
		</footer>
	);
};

export default Footer;
